import React from "react";

import Link from "next/link";
import { useState, useEffect } from "react";
import SidebarData from "../store/staticData/sidebar.json";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import ModalLayout from "../components/modals/ModalLayout";
import AuthSignUp from "../components/modals/AuthSignUp";
import AuthLogin from "../components/modals/AuthLogin";

import { openSignup } from "../store/actions/authActions";

import {
  setCurrentProfile,
  setAuthDestinationPage,
} from "../store/actions/rootActions";
import InternetDownModal from "../components/PlayPageComponents/internalComponents/InternetDownModal";
import Coin from "../public/assets/customAssets/PlayPageAssets/Coin";
import {
  pageVisitAttributes,
  gameHintsAttributes,
} from "../helpers/MoengageGamesAttributeCreators";
import moengageEvent from "../helpers/MoengageEventTracking";
import Hint from "../public/assets/customAssets/PlayPageAssets/Hint";
// ! Swiper
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
SwiperCore.use([Navigation, Pagination]);

function GsvLayout({ children, isLowPadding }) {
  return (
    <div
      className="spark-gsv-page-layout__wrapper"
      style={{
        backgroundImage: `url(${process.env.NEXT_PUBLIC_ALL_ASSETS_API}gsc-welcome-bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
      }}
    >
      <div className="spark-gsv-page">
        <div className="spark-dashboard-navbar gsv-footer">
          <div>
            <Link href="/">
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogo.png`}
                className="spark-dashboard-navbar--logo"
                alt="Spark Studio"
              />
            </Link>
          </div>
          <div className="spark-gsv-page__line"></div>

          <div>
            <Link href="/">
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}gsv-cup-icon-2x.png`}
                alt="Spark Studio"
                style={{ width: "5rem", marginLeft: "2rem" }}
              />
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`spark-dashboard-content spark-gsv-content gsv-footer${
          isLowPadding ? "low-padding" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default GsvLayout;
