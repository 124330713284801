import React from "react";
import GsvLayout from "../../containers/GsvLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton";

function GsvWelcome() {
  return (
    <GsvLayout>
      <div className="gsv-welcome-page">
        <div className="gsv-welcome-page__left">
          <div className="gsv-welcome-page__left--heading">
            {" "}
            Scan the QR code to download the app
          </div>
          <div
            className="gsv-welcome-page__left__container"
            style={{
              backgroundImage: `url(${process.env.NEXT_PUBLIC_ALL_ASSETS_API}qrcode-container.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center center",
              position: "relative",
            }}
          >
            <img
              className="gsv-welcome-page__left__container-img"
              src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}qr-code-app.png`}
            />
          </div>
          <PrimaryButton
            buttonText="Download App"
            buttonState="gsv-button-desktop"
            version="version-1"
            linkTo={
              "https://d28vdqftmyt2nj.cloudfront.net/gsv/the_studio_alpha.apk"
            }
            styles={{
              background: `url(${process.env.NEXT_PUBLIC_ALL_ASSETS_API}gsv_button_bg.png`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center center",
            }}
          />
          <div className="gsv-welcome-page__left--s-heading">
            {" "}
            Want unfettered access to the invisible-learning-app of the future?
            Write into{" "}
            <span style={{ fontWeight: 700 }}>app@sparkstudio.co</span> to get a
            universal login code.
          </div>
        </div>
        <div className="gsv-welcome-page__right">
          <div
            className="gsv-welcome-page__right__container"
            style={{
              backgroundImage: `url(${process.env.NEXT_PUBLIC_ALL_ASSETS_API}qrcode-container.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center center",
              position: "relative",
            }}
          >
            <img
              className="gsv-welcome-page__right__container-img"
              src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}spark_app_qr.png`}
            />
          </div>
          <PrimaryButton
            buttonText="Download App"
            buttonState="gsv-button-mobile"
            version="version-1"
            shine
            linkTo={
              "https://d28vdqftmyt2nj.cloudfront.net/gsv/the_studio_alpha.apk"
            }
          />
        </div>
      </div>
    </GsvLayout>
  );
}

export default GsvWelcome;
