import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
//  ! Sliding transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={600} />;
});
function ModalLayout({ children, className, handleClose, isOpen }) {
  return (
    <Dialog
      // onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      className={className}
    >
      {children}
    </Dialog>
  );
}

export default ModalLayout;
