import React from "react";

function Hint({ color, clickHandle }) {
  return (
    <img
      onClick={clickHandle}
      src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}hint_games_1.png`}
      className="game-window__left--hintIcon"
    />
  );
}

export default Hint;
